<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field reservationDate">
            <div class="title">기간</div>
            <ul class="content">
              <li class="item dateRange">
                <input-date-range
                  v-model="inputDateRangeValue"
                  type="lookup-condition"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">단체명/번호</div>
            <ul class="content">
              <li class="item">
                <input-text
                  v-model="searchConditions.resveGrpName"
                  @change="onGrpNameAndGrpNoChanged"
                />
              </li>
            </ul>
            <ul class="content">
              <li class="item">
                <input-text
                  v-model="searchConditions.resveGrpNo"
                  @change="onGrpNameAndGrpNoChanged"
                />
              </li>
            </ul>
            <ul class="content">
              <li class="item button">
                <ul class="button">
                  <li class="search">
                    <erp-button button-div="GET" @click.native="onGroupPopupOpen(true)">
                      검색
                    </erp-button>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">단체종류</div>
            <ul class="content">
              <li class="item">
                <ejs-multiselect
                  v-model="searchConditions.grpKindList"
                  cssClass="lookup-condition-multiselect"
                  :dataSource="searchOptions.grpKindOptions"
                  :fields="commonCodeFields"
                  placeHolder="전체"
                  width="240"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <ul class="content">
              <li class="item">
                <ul class="check">
                  <li>
                    <label>
                      <input
                        type="checkbox"
                        v-model="searchConditions.aditTeamFlag"
                      />
                      <i></i>
                      <div class="label">추가팀 보기</div>
                    </label>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :is-shortcut-button="true"
              :ignore="isPopupOpened"
              @click.native="onReservationBatchViewClicked"
          >
            조회
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <ReportView ref="reportViewComponent" :isPopup="true" />
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">예약 목록</div>
              <div class="header-caption">[{{ count }}건]</div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="print">
                  <erp-button
                      button-div="PRINT"
                      :is-shortcut-button="true"
                      :ignore="isPopupOpened"
                      @click.native="print"
                  >
                    인쇄
                  </erp-button>
                </li>
                <li>
                  <erp-button
                      button-div="SAVE"
                      :is-custom-shortcut-button="true"
                      shortcut-key="GroupReservationStatus.shortcuts.sendSms"
                      :shortcut="{key: 'F7'}"
                      :is-icon-custom="true"
                      :ignore="isPopupOpened"
                      @click.native="sendSms"
                  >
                    SMS전송
                  </erp-button>
                </li>
                <li class="print">
                  <erp-button
                      button-div="FILE"
                      @click.native="onClickExcel"
                  >
                    Excel
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body" style="border: none">
            <div class="body-grid">
              <ejs-grid-wrapper
                ref="groupReservationStatusListGrid"
                :allowResizing="true"
                :dataSource="groupReservationStatusList"
                :selectionSettings="selectionSettings"
                :allowExcelExport="true"
                :columns="groupReservationStatusListGridColumn"
                :provides="grid"
                :allowPaging="true"
                :pageSettings="groupReservationStatusPageSettings"
                @queryCellInfo="queryCellInfo"
                @actionComplete="groupReservationStatusListGridActionComplete"
              />
            </div>
          </div>
        </section>
      </article>
    </div>
    <group-popup
      v-if="isGroupPopupOpen"
      ref="groupPopup"
      @popupClosed="onGroupPopupClosed"
    ></group-popup>
    <reservation-sms-send
      v-if="isReservationSmsSendPopupOpen"
      ref="reservationSmsSendPopup"
      @popupClosed="reservationSmsSendPopupClose"
    />
  </div>
</template>

<script>
import ErpButton from "@/components/button/ErpButton.vue";
import commonMixin from "@/views/layout/mixin/commonMixin";
import InputDateRange from "@/components/common/datetime/InputDateRange";
import { MultiSelect, CheckBoxSelection } from "@syncfusion/ej2-dropdowns";
import groupPopup from "@/views/golf-reservation/popup/GroupPopup";
import ejsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import {
  Aggregate,
  Filter,
  Resize,
  Group,
  ExcelExport,
  ForeignKey,
  VirtualScroll,
  Page,
} from "@syncfusion/ej2-vue-grids";

import {getFormattedDate, getDayOfWeekCaption, getDayColorValue} from '@/utils/date';
import {
  commonCodesGetCommonCode,
  commonCodesGetComName,
  commonCodesGetColorValue,
  commonCodesGetCommonCodeAttrbNameByCodeAndIdx,
  commonCodeGetComCodeDefaultValue, commonCodesGetStandardInfo, commonCodesGetSortNo,
} from "@/utils/commonCodes";
import { gridUtilGetTelColumnAccess } from "@/utils/gridUtil";
import { numberWithCommas } from "@/utils/number";
import { orderBy as _orderBy } from "lodash";
import routeViewMixin from "@/views/layout/mixin/routeViewMixin";
import InputText from "@/components/common/text/InputText";

import { getGroupList } from "@/api/group";
import ReportView from "@/components/common/report/ReportView";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";

import moment from "moment";
import reservationSmsSend from "./popup/ReservationSmsSendPopup";
import { mapGetters } from "vuex";


import { SHORTCUT_KEYS } from "@/utils/KeyboardUtil";

MultiSelect.Inject(CheckBoxSelection);

export default {
  name: "GroupReservationStatus",
  components: {
    InputText,
    InputDateRange,
    groupPopup,
    ejsGridWrapper,
    reservationSmsSend,
    ReportView,
    ErpButton,
  },
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  computed: {
    ...mapGetters(["username"]),
    isPopupOpened() {
      return this.isGroupPopupOpen || this.isReservationSmsSendPopupOpen;
    },
    sendSmsButtonProps() {
      return {
        shortcutKey: "GroupReservationStatus.shortcuts.sendSms",
        shortcut: {
          ctrlKey: false,
          altKey: false,
          shiftKey: false,
          key: SHORTCUT_KEYS.F7,
        },
      };
    },
  },
  created() {
    this.inputDateRangeValue.from = getFormattedDate(new Date());
    this.inputDateRangeValue.to = getFormattedDate(new Date());

    this.searchOptions.grpKindOptions = commonCodesGetCommonCode("GRP_KIND");
  },
  data() {
    return {
      count: 0,
      isReservationSmsSendPopupOpen: false,
      groupReservationStatusListLength: 0,
      grid: [
        Aggregate,
        Filter,
        Resize,
        Group,
        ExcelExport,
        ForeignKey,
        VirtualScroll,
        Page,
      ],
      selectionSettings: { type: "Single", mode: "Both", enableToggle: false },
      commonCodeFields: { text: "comName", value: "comCode" },
      inputDateRangeValue: { to: null, from: null },
      searchConditions: {
        grpKindList: [],
        resveGrpName: null,
        resveGrpNo: null,
        aditTeamFlag: false,
      },
      searchOptions: {
        grpKindOptions: null,
      },
      groupReservationStatusList: [],
      isGroupPopupOpen: false,
      groupReservationStatusPageSettings: { pageSize: 50 },
      groupReservationStatusListGridColumn: [
        {
          field: "resveNo",
          visible: false,
          isPrimaryKey: true,
        },
        {
          field: "resveDate",
          headerText: "예약일자",
          textAlign: "center",
          width: 60,
          type: "string",
        },
        {
          field: "dwCode",
          headerText: "요일",
          textAlign: "center",
          groupCode: "DW_CODE",
          isCommonCodeField: true,
          width: 30,
        },
        {
          field: "bsnCode",
          headerText: "영업구분",
          textAlign: "center",
          groupCode: "BSN_CODE",
          isCommonCodeField: true,
          width: 40,
        },
        {
          field: "grpName",
          headerText: "단체명",
          width: 80,
          type: "string",
        },
        {
          field: "resveTeamCount",
          headerText: "예약팀수",
          textAlign: "center",
          width: 40,
          type: "number",
          format: "N",
        },
        {
          field: "resveCourse",
          headerText: "코스",
          textAlign: "center",
          width: 160,
        },
        {
          field: "resveTime",
          headerText: "시간",
          textAlign: "center",
          width: 40,
          type: "string",
        },
        {
          field: "generName",
          headerText: "총무명",
          width: 70,
          type: "string",
        },
        {
          field: "contactTel",
          headerText: "연락처",
          textAlign: "center",
          valueAccessor: this.gridUtilGetTelColumnAccess,
          width: 60,
          type: "string",
        },
        {
          field: "smsRecptnDiv",
          headerText: "SMS 수신동의",
          textAlign: "center",
          groupCode: "SMS_RECPTN_DIV",
          isCommonCodeField: true,
          width: 60,
          type: "string",
        },
        {
          field: "grpNo",
          headerText: "단체번호",
          width: 50,
          type: "string",
        },
        {
          field: "resveName",
          headerText: "예약자명",
          width: 80,
          type: "string",
        },
        {
          field: "contactName",
          headerText: "연락자명",
          width: 80,
          type: "string",
        },
      ],
    };
  },
  methods: {
    numberWithCommas,
    gridUtilGetTelColumnAccess,
    queryCellInfo(args) {
      const {
        cell,
        column: { field },
        data,
      } = args;
      const value = data[field];

      if (field === "dwCode") {
        cell.style.color = getDayColorValue(data.dwCode, data.bsnCode);
      } else if (field === "bsnCode") {
        cell.style.color = commonCodesGetColorValue("BSN_CODE", value);
      }
    },
    dwCodeValueAccess(field, data) {
      let code = data[field];
      if (code != null) {
        code = commonCodesGetComName("DW_CODE", code);
      }
      return code;
    },
    bsnCodeValueAccess(field, data) {
      let code = data[field];
      if (code != null) {
        code = commonCodesGetComName("BSN_CODE", code);
      }
      return code;
    },
    courseCodeValueAccess(field, data) {
      let code = data[field];
      if (code != null) {
        code = commonCodesGetComName("COURSE_CODE", code);
      }
      return code;
    },
    onGrpNameAndGrpNoChanged: function (args) {
      if (args.event && args.previousValue !== args.value) {
        if (args.value.trim() === "") {
          // 빈 칸인 경우에는 미 설정으로
          this.clearGrpInfo();
          return;
        }
        getGroupList({ searchValue: args.value })
          .then((response) => {
            const groupList = response.value.groupInfoList;
            if (groupList && groupList.length > 1) {
              this.onGroupPopupOpen();
            } else if (groupList && groupList.length === 1) {
              // 그룹정보 설정
              this.setGrpInfos(groupList[0]);
            } else {
              // 그룹정보 clear
              this.clearGrpInfo();
            }
          })
          .catch((error) => {
            console.log("getGroupList.err.===>", error);
          });
      }
    },
    setGrpInfos: function (grpInfo) {
      this.searchConditions.resveGrpNo = grpInfo.grpNo;
      this.searchConditions.resveGrpName = grpInfo.grpName;
    },
    clearGrpInfo: function () {
      this.searchConditions.resveGrpNo = null;
      this.searchConditions.resveGrpName = null;
    },
    onGroupPopupOpen: function (isOpenedWithButton = false) {
      this.isGroupPopupOpen = true;

      let groupPopupData = {};
      groupPopupData.grpName = this.searchConditions.resveGrpName
        ? (groupPopupData.grpName = this.searchConditions.resveGrpName)
        : null;
      if (isOpenedWithButton) {
        groupPopupData.isOpenedWithButton = isOpenedWithButton;
      }
      this.$nextTick(() => {
        this.$refs.groupPopup.openPopup(groupPopupData);
      });
    },
    onGroupPopupClosed: function (event) {
      this.isGroupPopupOpen = false;

      if (event.popupData.isOpenedWithButton) {
        // 단체검색으로 팝업을 Open 한 경우 : 검색하여 선택한 경우에만 처리함
        if (event.selectedGroup) {
          this.setGrpInfos(event.selectedGroup);
        }
      } else {
        // 단체 text 창 입력으로 팝업을 Open 한 경우
        if (event.selectedGroup) {
          this.setGrpInfos(event.selectedGroup);
        } else {
          this.clearGrpInfo();
        }
      }
    },
    onReservationBatchViewClicked() {
      this.getGroupReservationList();
    },
    async getGroupReservationList() {
      if (!this.inputDateRangeValue.from) {
        return this.errorToast(
          this.$t("main.validationMessage.requiredMessage", ["예약일자"])
        );
      }
      if (!this.inputDateRangeValue.to) {
        return this.errorToast(
          this.$t("main.validationMessage.requiredMessage", ["종료일자"])
        );
      }

      let params = {
        resveDateStart: this.inputDateRangeValue.from,
        resveDateEnd: this.inputDateRangeValue.to,
        grpKindList:
          this.searchOptions.grpKindOptions.length !==
          this.searchConditions.grpKindList.length
            ? this.searchConditions.grpKindList
            : undefined,
        resveGrpName: this.searchConditions.resveGrpName
          ? this.searchConditions.resveGrpName
          : undefined,
        resveGrpNo: this.searchConditions.resveGrpNo
          ? this.searchConditions.resveGrpNo
          : undefined,
        aditTeamFlag: this.searchConditions.aditTeamFlag,
      };

      const data = await GolfErpAPI.fetchGroupReservationStatus(params);

      this.groupReservationStatusList = _orderBy(data.map((item) => {
        item.smsRecptnDiv =
          item.smsRecptnDiv ||
          commonCodeGetComCodeDefaultValue("SMS_RECPTN_DIV");
        item.resveCourse =
            item.resveCourse.split(',').map(course => commonCodesGetComName('COURSE_CODE', course)).toString();
        item.courseCodeSortNo = commonCodesGetSortNo("COURSE_CODE", item.resveCourse);
        return item;
      }), ["resveDate", "courseCodeSortNo", "resveTime"]);
      this.groupReservationStatusListLength = data.length;
    },
    groupReservationStatusListGridActionComplete() {
      this.count = numberWithCommas(
        this.$refs.groupReservationStatusListGrid?.getGridBatchCount() || 0
      );
    },
    async setSendSmsData(data) {
      return new Promise(function (resolve) {
        let smsSendList = [];

        data.forEach((smsData) => {
          const reservationInfo = smsData;

          let sendData = {};

          sendData.sendKey = reservationInfo.resveNo;
          sendData.resveDate = reservationInfo.resveDate;
          sendData.dwName = getDayOfWeekCaption(
            moment(reservationInfo.resveDate).toDate()
          );
          sendData.resveCourse = reservationInfo.resveCourse;
          sendData.resveTime = reservationInfo.resveTimes;
          sendData.transFlag = false;
          sendData.resveCourseTime = reservationInfo.resveCourseTimes;
          sendData.timeId = reservationInfo.timeId;
          sendData.urlName = (commonCodesGetStandardInfo("noneChkinUseFlag") ?
            reservationInfo.resveName : null);
          sendData.urlContactTel = (commonCodesGetStandardInfo("noneChkinUseFlag") ?
            reservationInfo.contactTel : null);
          sendData.urlResveId = reservationInfo.resveId;
          sendData.grpName = reservationInfo.grpName || "";
          sendData.teamCnt = reservationInfo.resveTeamCount;

          const weekdayCncde = commonCodesGetStandardInfo("weekdayCncde");
          const weekendCncde = commonCodesGetStandardInfo("weekendCncde");
          const weekdayCnctime = commonCodesGetStandardInfo("weekdayCnctime");
          const weekendCnctime = commonCodesGetStandardInfo("weekendCnctime");

          sendData.cancelDate = moment(reservationInfo.resveDate).add(reservationInfo.bsnCode === "WEEKDAY" ? weekdayCncde * -1 : weekendCncde * -1, "d").format("YYYY-MM-DD");
          sendData.cancelTime = reservationInfo.bsnCode === "WEEKDAY" ? weekdayCnctime : weekendCnctime;

          if (reservationInfo.smsRecptnDiv === "B") {
            if (reservationInfo.resveMngrHp) {
              sendData.receiverName = reservationInfo.resveName;
              sendData.name =
                reservationInfo.resveName +
                " " +
                commonCodesGetCommonCodeAttrbNameByCodeAndIdx(
                  "MEMBER_DIV",
                  reservationInfo.memberDiv,
                  1
                );
              sendData.contactTel = reservationInfo.resveMngrHp;
              sendData.smsSendFlag = true;

              smsSendList.push(JSON.parse(JSON.stringify(sendData)));
            }
          } else if (reservationInfo.smsRecptnDiv === "A") {
            sendData.receiverName = reservationInfo.resveName;
            sendData.name =
              reservationInfo.resveName +
              " " +
              commonCodesGetCommonCodeAttrbNameByCodeAndIdx(
                "MEMBER_DIV",
                reservationInfo.memberDiv,
                1
              );

            if (reservationInfo.contactTel) {
              sendData.contactTel = reservationInfo.contactTel;
              sendData.smsSendFlag = true;

              smsSendList.push(JSON.parse(JSON.stringify(sendData)));
            }

            if (reservationInfo.resveMngrHp) {
              sendData.contactTel = reservationInfo.resveMngrHp;
              sendData.smsSendFlag = true;

              smsSendList.push(JSON.parse(JSON.stringify(sendData)));
            }
          } else {
            if (reservationInfo.contactTel) {
              sendData.receiverName = reservationInfo.resveName;
              sendData.name =
                reservationInfo.resveName +
                " " +
                commonCodesGetCommonCodeAttrbNameByCodeAndIdx(
                  "MEMBER_DIV",
                  reservationInfo.memberDiv,
                  1
                );
              sendData.contactTel = reservationInfo.contactTel;
              sendData.smsSendFlag = true;

              smsSendList.push(JSON.parse(JSON.stringify(sendData)));
            }
          }
        });

        resolve(smsSendList);
      });
    },
    async sendSms() {
      // const batchCurrentViewRecords = this.$refs.groupReservationStatusListGrid.getBatchCurrentViewRecords();
      const batchCurrentViewRecords = this.groupReservationStatusList;

      if (!(batchCurrentViewRecords.length > 0)) {
        this.errorToast("SMS 전송할 자료가 없습니다.");
        return;
      }

      let smsSendList = [];
      await this.setSendSmsData(batchCurrentViewRecords)
        .then((response) => {
          smsSendList = response;
        })
        .catch((error) => {
          console.log("setSendSmsData.===>", error);
        });

      this.isReservationSmsSendPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.reservationSmsSendPopup.showReservationSmsSendPopup(
          smsSendList,
          "G_RESVE_GROUP",
          false
        );
      });
    },
    commonCodesGetColorValue,
    reservationSmsSendPopupClose() {
      this.isReservationSmsSendPopupOpen = false;
    },
    print: function () {
      const options = [
        {
          field: "dwCode",
          comCode: "DW_CODE",
          format: "comCode",
        },
        {
          field: "bsnCode",
          comCode: "BSN_CODE",
          format: "comCode",
        },
        {
          field: "resveCourse",
          comCode: "COURSE_CODE",
          format: "comCode",
        },
        {
          field: "contactTel",
          format: "tell",
        },
      ];

      const result = this.$refs.groupReservationStatusListGrid.getGridBatchData(
        options
      );
      if (result.length < 1) {
        return this.errorToast(this.$t("report.popupMessage.noData"));
      }

      const searchOptionsList = [
        {
          key: "기간",
          value:
            this.inputDateRangeValue.from + " ~ " + this.inputDateRangeValue.to,
        },
        {
          key: "단체명",
          value: this.searchConditions.resveGrpName,
        },
        {
          key: "단체번호",
          value: this.searchConditions.resveGrpNo,
        },
        {
          key: "단체종류",
          value: this.searchConditions.grpKindList
            .map(
              (item) =>
                this.searchOptions.grpKindOptions.find(
                  (i) => i.comCode === item
                ).comName
            )
            .join(", "),
        },
      ];

      const searchOptions = searchOptionsList
        .filter((item) => !!item.value)
        .map((item) => item.key + ": " + item.value)
        .join(", ");

      const reportData = {
        reportJson: {
          jsonData: result,
          username: this.username,
          uniqueCode: this.$options.name,
          searchOptions,
        },
      };

      this.$refs.reportViewComponent.postReport(reportData, this.$options.name);
    },
    onClickExcel() {
      this.$refs.groupReservationStatusListGrid.excelExport();
    },
  },
};
</script>
